
.rowItem{
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 15px;
    width: 300px;
    min-width: 250px;
    max-width: 350px;
    overflow: hidden;
    box-shadow: 0 0 5px rgb(219, 219, 219);
}

.textWhite{
    color: #6ec1e4;
    padding-top: 20px;
    padding-bottom: 10px;
}

.rowItemSub{
    
    border-radius: 10px;
    height: 360px;
    overflow: hidden;
}

.rowItem:hover .addToCart{
    bottom: 46px;
    opacity: .9;
    transition: .5s;
}

.rowItem:hover .addSide{
    
    opacity: .7;
    translate: 0px 0px;
    transition: .5s;
}

.rowItem:hover .resize{
    transform: scale(2);
  transition: .5s;
}



.resize{
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: 1s;
    
}

.resize_icon{
  width: auto;
  height: auto;
}

.roundEdge{
    border-radius: 10px;
}

.addToCart{
    position: relative;
    bottom: 0px;
    margin: 0 auto;
    z-index: 1000;
    width: 100%;
    background-color: #8224e3;
    text-align: center;
    color: white;
    padding: 10px;
    opacity: 1;
    transition: .5s;
}



.addToCart a{
 
  color: white;
  
}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .addSide{
    position: relative;
    float: right;
    display: flex;
    flex-direction: column;
    margin-top:-390px;
    margin-right: 10px;
    z-index: 1000;
    width: fit-content;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 10px;
    opacity: .2;
    translate: 60px 0px;
  }
  .textDark{
    color: black;
  }
  .caption > p{
    margin: 0px;
    line-height: 25px;
    padding: 0px;
    margin: 0 auto;
    text-align: center;
    color: gray;
    font-size: 14px;
  }
  .caption{
    height: fit-content;
    font-size: 14px;
    text-align: center;
  }

  .quickView{
    text-decoration: none;
    line-height: 25px;
    padding: 0px;
    margin: 0 auto;
    text-align: center;
    color: rgb(58, 58, 58);
    padding: 10px;
    width: 100%;
    border-style: none;
    background-color: rgb(245, 245, 245);
    margin-top: 10px;
  }

  
  .quickView:hover{
    background-color: rgb(202, 202, 202);
    width: 100%;
    padding: 10px;
    transition: .5s;

  }

.brands{
  text-align: center;
  padding-bottom: 20px;
}

.brands p{
  color:gray;
}
.brands hr{
  width: 30px;
  margin: 0 auto;
}

.brands h4{
  line-height: 30px;
  margin: 0px;
}


.resizeIcon{
  width: 20px;
}

.centerItem{
  line-height: 20px;
  font-size: 15px;
  text-align: center;
  color: rgb(56, 56, 56);
  margin-top: 10px;
}


.centerTitile{
  line-height: 20px;
  font-size: 15px;
  text-align: center;
  color: rgb(121, 121, 121);
}

.imgCon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 362px;
  background-color: rgb(243, 243, 243);
}

.loginLink , 
.loginLink2{
  background-color: transparent;
}

.addToCart:hover{
  background-color: #ff28d4;
  opacity: .9;
}

.loginLink2:hover {
  background-color: #ff28d4;
  opacity: .9;
}

.loginLink2:hover svg{
  color: white;
  transition: .5s;
  font-weight: bold;
}

.loginLink svg{
  margin: 0px;
  padding: 0px;
  color: black;
}


.loginLink2 svg{
  margin: 0px;
  padding: 0px;
  color: rgb(255, 255, 255);
}


.shortText{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.flexRow{
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-bottom: 0px;
  gap:10px;
}
.colorCircle{
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border-style: none;
  
}

.svgColor{
  color: white;
}


@media only screen and (max-width: 1024px) {
  
  .row{
    flex-direction: column;
  }
  rowItem {
   
    width: 100%;
   
  }
 }
 
 @media only screen and (max-width: 768px) {

  .rowItem{
    
    max-width: 100%;
    
}

  .recent > div {
    width: 90%;
    
  }
   
  .row{
    
    flex-direction: column;
    
  }
  .rowItem{
   
    width: 100%;
   
  }
 
 }