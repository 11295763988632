.root {
}

.inputError {
  border: var(--borderErrorField);
}
.redColor{

  font-size: 14px;
  line-height: 10px;
  margin: 0px;
  padding: 0px;
}