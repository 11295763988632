.aside{

    display: flex;
    flex-direction: column;
    gap: 20px;
    
}

.imgCon{
    height: 500px;
}

.imgCon img{
    width: 100%;
    height: 100%;
    
}

h4{
    font-size: 16px;
    color: black;
}



.overlay{
    display: flex;
    justify-content: flex-start;
    gap:20px;
    margin-top: -8%;
    color: white;
}

.overlay h3{
    color: white;
    font-size: 30px;
    margin-top: 5px;
}

p{
    color: black;
    padding: 0px;
    margin: 0px;
    line-height: 15px;
}

.overlay img{
    border-style: solid;
    border-width: thick;
    border-color: white;
    margin-left: 20px;
}


.overlay svg{
    color: black;
}

.resize{
    width: 150px;
    height: 150px;
}

.svgCon{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.svgCon{
    margin-left: -5px;
}

.conBorder{
    border-style: solid;
    border-width: thin;
    border-color: rgb(206, 206, 206);
    padding-bottom: 20px;
}

hr{
    width: 100%;
    margin: 20px 0px;
}

.centerItem{
    text-align: center;
    padding: 20px;
}

.link{
    padding: 10px;
    border-radius: 20px;
    background-color: #9b51e0;
    color: white;
    margin: 20px;
    border-style: none;
    cursor:not-allowed;
}


.link:hover{
    
    background-color: pink;
}

.row{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.textShadow{
    text-shadow: 0 0 5px gray;
}

.containerMain{
    width: 60%;
}

.captionText{
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: fit-content;
    align-self: center;
}

.noMargin{
    margin: 0px;
    margin-top: 5px;
}

.socialBtn{
    width: 50px;
    border-radius: 50%;
}